import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Helmet from 'react-helmet'
import Heading from 'components/Heading'
import OverlayBottom from 'components/OverlayBottom'
import Markdown from 'components/styled/Markdown'
import ContactForm from './Form'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, header, success } = frontmatter

  const [submitted, setSubmitted] = React.useState(false)

  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <OverlayBottom
        sizes={header.image.childImageSharp.sizes}
        imageSize={submitted ? 'small' : 'large'}
        imageOverlayColor="purple"
      >
        <Heading align="center" color="purple">
          {submitted ? success.title : header.title}
        </Heading>
        {submitted ? (
          <Markdown source={success.description} color="purple" />
        ) : (
          <ContactForm onSubmitted={() => setSubmitted(true)} />
        )}
      </OverlayBottom>
    </Fragment>
  )
}

export const query = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        success {
          title
          description
        }
      }
    }
  }
`
