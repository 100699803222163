import React from 'react'
import styled from 'styled-components'
import { object, string } from 'yup'

import { RadioGroup, Radio } from 'components/ReactRadio'
import { Formik, Form, Field } from 'formik'

export default ({ onSubmitted }) => (
  <Formik
    initialValues={{
      reason: 'tuition',
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    }}
    validationSchema={object().shape({
      reason: string().required('Please select an option.'),
      firstName: string().required('Please enter your name.'),
      lastName: string(),
      email: string()
        .email('Please enter a valid email address.')
        .required('Please provide an email so we can get back to you.'),
      message: string().required(
        'Let us know what it is we can help you with today.'
      ),
    })}
    onSubmit={(values, { setSubmitting }) => {
      fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((response) => {
          setSubmitting(false)
          onSubmitted && onSubmitted()
        })
        .catch((error) => {
          setSubmitting(false)
          alert('Unexpected server error. Please try again.')
        })
    }}
    render={({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue,
    }) => (
      <ContactForm name="contact">
        <Question>What can we help you with?</Question>
        <StyledRadioGroup
          name="reason"
          selectedValue={values.reason}
          onChange={(val) => setFieldValue('reason', val)}
        >
          <StyledRadio value="tuition" />
          <StyledRadio value="enrollment" />
          <StyledRadio value="generalInquiry" />
        </StyledRadioGroup>
        <Question>
          Fill out the form below and we will get back to you as soon as
          possible!
        </Question>
        {errors.firstName && touched.firstName && (
          <Error>{errors.firstName}</Error>
        )}
        <NameInputContainer>
          <Input name="firstName" placeholder="First Name" />
          <Input name="lastName" placeholder="Last Name" />
        </NameInputContainer>
        {errors.email && touched.email && <Error>{errors.email}</Error>}
        <Input name="email" placeholder="Email Address" />
        {errors.message && touched.message && <Error>{errors.message}</Error>}
        <MessageInput
          component="textarea"
          name="message"
          placeholder="How can we help you?"
        />
        <Submit type="submit">SUBMIT</Submit>
      </ContactForm>
    )}
  />
)

const ContactForm = styled(Form)`
  display: flex;
  flex-direction: column;
`
const Question = styled.h2`
  margin: 20px 0 15px;
  padding: 0;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 16pt;
  line-height: 20pt;
  letter-spacing: 0;
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};

  @media (max-width: ${(props) => props.theme.tablet}px) {
    text-align: center;
  }
`
const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.phone}px) {
    flex-direction: column;
    margin-left: 20px;
  }
`
const StyledRadio = styled(Radio)`
  margin: 5px;
  appearance: button;
  border: 4px solid #ccc;
  border-top-color: #bbb;
  border-left-color: #bbb;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  &:checked {
    border: 8px solid ${(props) => props.theme.purple};
  }
  &:focus {
    outline: none;
  }
`
const NameInputContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${(props) => props.theme.tablet}px) {
    justify-content: center;
    flex-direction: column;
  }
`
const Input = styled(Field)`
  margin: 10px 0;
  padding: 10px 2%;
  width: 100%;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 300;
  font-size: 14pt;
  color: ${(props) => props.theme.primaryColor};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  transition-duration: 0.3s;
  &:first-of-type {
    margin-right: 8px;
  }
  &::placeholder {
    color: ${(props) => props.theme.secondaryColor};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.purple};
    box-shadow: ${(props) => props.theme.boxShadow};
    outline: none;
    &::placeholder {
      color: #dedede;
    }
  }

  @media (max-width: ${(props) => props.theme.tablet}px) {
    &:first-of-type {
      margin-right: 0;
    }
  }
`
const MessageInput = styled(Input)`
  resize: none;
  height: 150px;
  box-sizing: border-box;
`
const Submit = styled.button`
  margin: 10px auto;
  padding: 10px 40px;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 600;
  font-size: 14pt;
  background: white;
  color: ${(props) => props.theme.primaryColor};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  transition-duration: 0.5s;
  cursor: pointer;
  &:hover {
    box-shadow: ${(props) => props.theme.boxShadow};
    color: white;
    background: ${(props) => props.theme.purple};
    border: 1px solid ${(props) => props.theme.purple};
  }
`
const Error = styled.span`
  color: red;
  transition-duration: 0.5s;
`
